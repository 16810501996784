@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Somar Sans";
  src: url("../public/fonts/SomarSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Somar Sans", sans-serif;
  overflow: hidden;
  overflow: auto;
}

.custom-dot {
  width: 20px;
  height: 1px;
  background-color: gray;
}

.slick-dots .slick-active .custom-dot {
  background-color: black;
}

.fadeIn {
  animation: fadeIn 2s ease-in-out;
}

.moveText {
  animation: moveText 3s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.slick-prev,
.slick-next {
  z-index: 1;
  color: white;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  color: white;
}

.slick-dots li button:before {
  font-size: 12px;
  color: gray;
}

.custom-dot {
  height: 8px;
  width: 25px;
  border-radius: 2px;
}

:root {
  --foreground: #171717;
}

img,
video {
  max-width: 100%;
  height: revert-layer;
}

.css-1jk99ih-MuiInputBase-input-MuiOutlinedInput-input {
  height: 12px;
  min-width: 250px;
}

.custom-datepicker {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 8px;
  font-size: 16px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-weight: 500;
  color: #545E64;
}

.dateInput {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
}

.custom-datepicker input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 8px;
  font-size: 16px;
  color: #1e2024;
}

.inputField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 8px;
  height: 56px;
  font-size: 16px;
  color: #545e64;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.flexContainer {
  flex: 1 1 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  height: 56px;
  font-size: 16px;
  color: #545e64;
  font-weight: 700;
  margin-top: 9px;
}

.textareaField {
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 8px;
  height: 200px;
  font-size: 16px;
  color: #545e64;
  font-weight: 700;
}

.submitButton {
  padding: 18px;
  background-color: #000000;
  color: white;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  font-weight: 800;
}

.submitButton.ltr {
  width: 50% !important;
}

.submitButton.rtl {
  width: 100% !important;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

.formLabel {
  cursor: pointer;
  color: #1e2024;
  font-size: 14px;
  width: 100%;
  font-weight: 700;
}
.custom-offcanvas {
  width: 600px !important;
  background-color: #f8f9fa;
}

.custom-header {
  background-color: #121212;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.custom-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
}

.custom-body {
  padding: 20px;
  background-color: #ffffff;
}

.custom-close-btn {
  background-color: #dc3545;
  border: none;
  padding: 10px 20px;
  text-transform: capitalize;
}

.custom-close-icon {
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

